export default {
  VacationsBookingForm__BOOKABLE_DATE_SCHEDULE_EXTENSION:
    "Check back soon for our next schedule extension.",
  VacationsBookingForm__CALENDAR_CAPTION_FORMAT: "ddd, MMM DD, YYYY",
  VacationsBookingForm__CALENDAR_DATE_STRING_FORMAT: "MMMM DD, YYYY",
  VacationsBookingForm__CALENDAR_INPUT_FORMAT: "MM/DD",
  VacationsBookingForm__CLOSE: "Close",
  VacationsBookingForm__DATE_SELECTOR_TITLE: "Select your travel dates",
  VacationsBookingForm__DAYS_OF_WEEK_INITIALS: "SMTWTFS",
  VacationsBookingForm__DEPART_DATE: "Depart",
  VacationsBookingForm__DEPART_DATE_ARIA: "Depart date in M M, D D format",
  VacationsBookingForm__FROM: "From",
  VacationsBookingForm__FIND_A_VACATION: "Find a Vacation",
  VacationsBookingForm__INVALID_ROUTE: "Enter a valid departure airport",
  VacationsBookingForm__LAST_BOOKABLE_DATE: (airDatesData) =>
    `We are currently accepting vacations package booking through ${airDatesData.formattedCurrentLastDateString}.`,
  VacationsBookingForm__NO_MATCH_FOUND: "No match found",
  VacationsBookingForm__ON_OPEN_SCHEDULE: (airDatesData) =>
    `On ${airDatesData.formattedFutureOpenDateString} we will open our schedule for sale through ${airDatesData.formattedFutureCloseDateString}.`,
  VacationsBookingForm__RETURN_DATE: "Return",
  VacationsBookingForm__RETURN_DATE_ARIA: "Return date in M M, D D format",
  VacationsBookingForm__SUBJECT_TO_CHANGE:
    "This date is subject to change! Please check back frequently.",
  VacationsBookingForm__TO: "To",
};
