import AutocompleteStations from "./AutocompleteStations/AutocompleteStations.i18n";
import FlightDurationI18nKeys from "./FlightDuration/FlightDuration.i18n";
import FlightInvalidI18nKeys from "./FlightInvalid/FlightInvalid.i18n";
import FlightNumberI18nKeys from "./FlightNumber/FlightNumber.i18n";
import FlightStopsI18nKeys from "./FlightStops/FlightStops.i18n";
import FlightTimeI18nKeys from "./FlightTime/FlightTime.i18n";
import ItineraryRecentSearchI18nKeys from "./ItineraryRecentSearch/ItineraryRecentSearch.i18n";
import NextDayIndicatorKeys from "./NextDayIndicator/NextDayIndicator.i18n";
import OvernightIndicatorKeys from "./OvernightIndicator/OvernightIndicator.i18n";
import PlaneTypeI18nKeys from "./PlaneType/PlaneType.i18n";
import StopDurationI18nKeys from "./StopDuration/StopDuration.i18n";
import TripOriginDestinationDetailKeys from "./TripOriginDestinationDetail/TripOriginDestinationDetail.i18n";

export default {
  ...AutocompleteStations,
  ...FlightDurationI18nKeys,
  ...FlightInvalidI18nKeys,
  ...FlightNumberI18nKeys,
  ...FlightStopsI18nKeys,
  ...FlightTimeI18nKeys,
  ...ItineraryRecentSearchI18nKeys,
  ...NextDayIndicatorKeys,
  ...OvernightIndicatorKeys,
  ...PlaneTypeI18nKeys,
  ...StopDurationI18nKeys,
  ...TripOriginDestinationDetailKeys,
};
