export default {
  PackageCard__DEPART: "Depart",
  PackageCard__DEPART_DATE_FORMAT: "MMM DD, YYYY",
  PackageCard__DURATION: (duration) => `${duration} nights hotel + flight from`,
  PackageCard__EARN_POINTS: (points) => `earn from ${points} points per member`,
  PackageCard__EXPLORE_PACKAGE: "Explore package",
  PackageCard__FROM: "from",
  PackageCard__IMAGE_ALT_TEXT: "Vacation package destination image",
  PackageCard__PER_GUEST: "per guest",
};
