import BlockPlacementI18nKeys from "./BlockPlacement/BlockPlacement.i18n";
import CarouselPlacementI18nKeys from "./ListPlacements/CarouselPlacement.i18n";
import FeatureItemI18nKeys from "./ListPlacements/FeatureItem/FeatureItem.i18n";
import ErrorPlacementI18nKeys from "./PlacementFactory/ErrorPlacement/ErrorPlacement.i18n";

export default {
  ...BlockPlacementI18nKeys,
  ...CarouselPlacementI18nKeys,
  ...ErrorPlacementI18nKeys,
  ...FeatureItemI18nKeys,
};
