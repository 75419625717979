import AuthProviderI18nKeys from "./AuthProvider/AuthProvider.i18n";
import BaseAuthenticationFormI18nKeys from "./components/BaseAuthenticationForm/BaseAuthenticationForm.i18n";
import AccountHeldErrorI18nKeys from "./components/LoginForm/AccountHeldError/AccountHeldError.i18n";
import LoginFormI18nKeys from "./components/LoginForm/LoginForm.i18n";
import MfaContactSelectFormI18nKeys from "./components/MfaContactSelectForm/MfaContactSelectForm.i18n";
import MfaHeadingI18nKeys from "./components/MfaHeading/MfaHeading.i18n";
import MissingInformationRedirectDialogKeys from "./components/MissingInformationRedirectDialog/MissingInformationRedirectDialog.i18n";
import NewPasswordI18nKeys from "./components/NewPassword/NewPassword.i18n";
import loginSchemaI18nKeys from "./schema/loginSchema.i18n";

export default {
  ...AccountHeldErrorI18nKeys,
  ...AuthProviderI18nKeys,
  ...BaseAuthenticationFormI18nKeys,
  ...LoginFormI18nKeys,
  ...loginSchemaI18nKeys,
  ...MfaContactSelectFormI18nKeys,
  ...MfaHeadingI18nKeys,
  ...MissingInformationRedirectDialogKeys,
  ...NewPasswordI18nKeys,
};
