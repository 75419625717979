export default {
  MfaContactSelectForm__EMAIL_ACTION: "Email me a verification code at",
  MfaContactSelectForm__NEED_HELP: "Need help?",
  MfaContactSelectForm__PHONE_ACTION: "Text me a verification code at",
  MfaContactSelectForm__PROMPT: "How would you like to verify your identity?",
  MfaContactSelectForm__SUBMIT: "Continue",
  MfaContactSelectForm__TERMS_AGREE: "I agree to the",
  MfaContactSelectForm__TERMS_AGREE_REQUIRED: "You must agree to the terms and conditions.",
  MfaContactSelectForm__TERMS_AND_CONDITIONS: "terms and conditions.",
};
