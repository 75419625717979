import RecentSearchProviderI18nKeys from "./RecentSearchProvider/RecentSearchProvider.i18n";
import setYupLocaleI18nKeys from "./setYupLocale/setYupLocale.i18n";
import StateAndCountryAutocompleteI18nKeys from "./StateAndCountryAutocomplete/StateAndCountryAutocomplete.i18n";
import SuffixAutocompleteI18nKeys from "./SuffixAutocomplete/SuffixAutocomplete.i18n";
import TimeFormFieldI18nKeys from "./TimeFormField/TimeFormField.i18n";

export default {
  ...RecentSearchProviderI18nKeys,
  ...setYupLocaleI18nKeys,
  ...StateAndCountryAutocompleteI18nKeys,
  ...SuffixAutocompleteI18nKeys,
  ...TimeFormFieldI18nKeys,
};
